"use client"

import styles from "./page.module.css";
import dynamic from "next/dynamic";

// Load nextui dynamically
const Avatar = dynamic(() => import('@heroui/avatar').then(m => m.Avatar), { loading: () => <p>Laster inn bilde...</p> })
const Button = dynamic(() => import('@heroui/button').then(m => m.Button), { loading: () => <a>Laster inn knapp...</a> })

import Image from "next/image";
import Link from "next/link";

import Header from "~/components/header";
import SmallCard from "~/components/cards/small";
import LargeCard from "~/components/cards/large";

import LiumA from "./images/lium/A.jpg";
import LiumB from "./images/lium/B.jpg";
import LiumC from "./images/lium/C.jpg";
import Leponex from "./images/wbc_diff.png";
import Lasse from "./images/lasse.jpg";

import Puzzle from "./images/puzzle.svg";
// import Hands from "./images/hands.svg?url";

import IconPeople from "./images/meeting.svg";
import IconSparkles from "./images/sparkles.svg";
import IconShield from "./images/shield.svg";
import staticImageLoader from "~/loaders/static-image-loader";
import SocialShare from "~/components/metadata";

export default function Home() {
	return (
		<>
			<>
				<title>Heldøgn Bo- og Omsorgstjenester | Ascendi</title>
				<meta name="description" content="Vi tilbyr bo- og omsorgstjenester til de opplever utfordrende atferd i sammenheng med underliggende psykiske og somatiske lidelser, ROP eller utviklingshemning"/>
				<link rel="canonical" href="https://www.ascendi.no/"/>
				<SocialShare/>
			</>
			<Header/>
			<section id="tjenester" className={styles.services}>
				<SmallCard icon={IconPeople} title="Miljøterapi i Fokus">
					Vi fokuserer på å forstå og håndtere utfordrende atferd, 
					og gir beboeren en mulighet til mestring på en konstruktiv måte.
				</SmallCard>
				<SmallCard icon={IconSparkles} title="Medisinsk Oppfølging">
					Vi samarbeider med spesialist-helsetjenesten og følger opp medisinering <span className="font-bold">inhouse</span>, 
					slik at beboerne slipper unødvendige reiser til sykehuset.
				</SmallCard>
				<SmallCard icon={IconShield} title="Rus og Psykiatri (ROP)">
					Vi jobber med beboere som sliter med psykiske problemer, og noen sliter med rus i tillegg.
				</SmallCard>
				<div className="md:col-span-3 md:py-16 p-2 bg-card">
					<div className="md:grid-cols-2 grid lg:gap-20 gap-8 mx-auto py-12 max-w-screen-2xl">
						<div className="md:col-start-2 flex flex-col select-none w-full justify-center relative max-w-lg mx-auto text-foreground">
							<Puzzle/>
						</div>
						<div className="md:row-start-1 max-w-lg flex flex-col justify-center mx-auto">
							<h2 className="text-foreground text-2xl font-bold pb-6">Tilpasset omsorg</h2>
							<p className="text-foreground pb-4">
								Våre beboere føler seg akseptert og forstått uansett bakgrunn. Vi vektlegger egenart som grunnlag for trivels og utvikling, og tilpasser omsorg og støtte individuelt.
							</p>
							<p className="text-foreground pb-4">
								Gjennom miljøterapautisk arbeid hjelper vi beboerne å mestre utfordrende atferd på en konstruktiv måte. Samtidig samarbeider vi tett med pårørende, primær- og spesialisthelsetjenesten.
							</p>
							<Button 
								className="mt-8 mx-auto max-w-64 w-1/2 font-semibold bg-[#2540e5] text-white"
								as={Link}
								href="https://www.ascendi.no/blog/malrettet-miljoarbeid/"
								// color="warning"
								radius="md"
							>
								Finn ut mer
							</Button>
						</div>
					</div>
				</div>
				<LargeCard subtitle="Bosenter" title="Lium Gård" img={LiumA}>
					<p>
						Vi holder til på Lium gård, kun 20 minutter unna Eidsvoll. Her er det plass til 3 beboere med fagpersonale døgnet rund.
					</p>
				</LargeCard>
				<LargeCard subtitle="" title="" img={LiumB}>
					<p>
						Bostedet ligger på et skjermet område, ute i naturen. Pårørende er velkomne til overnattingsbesøk etter avtale.
					</p>
				</LargeCard>
				<LargeCard subtitle="" title="" img={LiumC}>
					<p>
						Gården har egen strandlinje ut mot mjøsa, hvor vi tilbyr privat bading, grilling og båturer på Mjøsa.
					</p>
				</LargeCard>
				<div className="md:col-span-3 grid lg:grid-cols-2 gap-1">
					<div className="flex justify-center relative bg-card overflow-hidden">
						<Image
							src={Leponex.src}
							width={Leponex.width}
							height={Leponex.height}
							className="select-none py-20 w-full max-w-lg"
							alt={"Bilde av vår HemoCue WBC DIFF maskin."}
							sizes="(max-width: 768px) 100vw, 25vw"
							loader={staticImageLoader}
							style={{
								objectFit: "contain",
							}}
						/>
					</div>
					<div className="flex py-20 bg-card">
						<div className="max-w-lg flex-col mx-3 lg:mx-4 mx-auto">
							<h2 className="text-foreground text-2xl font-bold pb-6">Medisinsk oppfølging</h2>
							<p className="text-foreground pb-4">
								Mange med alvorlige psykiske lidelser, som paranoid schizofreni, kan ha utfordringer med å akseptere tradisjonelle medisinske prosedyrer, inkludert venøse blodprøver. 
								For disse kan kapillære blodprøver være et avgjørende alternativ.
							</p>
							<p className="text-foreground pb-4">
								Kapillære blodprøver, som kan tas raskt og enkelt ved et fingerstikk, reduserer ubehaget og stresset som ofte er forbundet med mer invasive metoder. 
								Dette er spesielt viktig for de med paranoid schizofreni, som kan oppleve frykt, mistro eller angst i møte med medisinske prosedyrer.
							</p>
							<p className="text-foreground pb-4">
								Hos oss bruker vi blant annet HemoCue® WBC DIFF Analyzer, et nøyaktig og brukevennlig instrument for leukocyttelling. 
								Dette gjør det mulig å følge opp medisiner som Leponex og fentiazinderivater på en skånsom måte. 
								Slik kan vi møte beboerenes behov på en helhetlig og tilpasset måte.
							</p>
						</div>
					</div>
				</div>
			</section>
			<section id="quote" className="py-12 bg-dark">
				<div className="max-w-screen-xl mx-auto"
					style={{
						display:"flex",
						justifyContent:"center",
						flexDirection:"column",
						textAlign:"center",
					}}
				>
					<h3 className="text-xl text-white lg:text-2xl lg:w-3/4 mx-auto p-1">Vi bygger selvtillit. Vi tror på å skape en trygg og stabil hverdag som fremmer personlig utvikling og velvære.</h3>
					<p className="text-white/60 font-light">- Lasse Toroczkay, psykiatrisk sykepleier på Ascendi</p>
					<Avatar className="mx-auto mt-4 w-16 h-16 text-large"
						src={`${Lasse.src}?w=64`}
						name="LT"
						color="danger"
						// size="lg"
						isBordered
					/>
				</div>
			</section>
			{/* <section id="sikkerhet" className="px-4 bg-light">
				<div className={`${styles.section} max-w-screen-xl mx-auto md:grid-cols-4 grid-cols-1 grid gap-10`}>
					<div className="md:col-span-2 flex flex-col justify-center">
						<h2 className="text-black text-2xl font-bold pb-6">En trygg og stabil hverdag</h2>
						<p className="text-black pb-4">
							Vi evaluerer og forbedrer sikkerhetstiltak som inkluderer beredskapsprosedyrer, 
							ansatte som gjennomgår grundig opplæring og jevnlige risikovurderinger.
						</p>
						<p className="text-black pb-6">
							I vår tilnærming legger vi vekt på kvalifisert og kompetent personale som er i stand til å håndtere utfordrende atferd. 
							Vår personalgruppe er håndplukket av ledelsen, som jobber i team med erfaring og kompetanse innen denne spesifikke domenen.
						</p>
						
						<p className="text-black pb-6">
							En sentral del av å sikre både beboernes og personalets velvære er evnen til å analysere og unngå utfordrende situasjoner. 
						</p>
						<p className="text-black pb-6">
							Vi styrker personalets utholdenhet og evne til å levere omsorg i krevende situasjoner. 
							Vi opprettholder en åpen og jevnlig kommunikasjon med alle for å håndtere og løse eventuelle sikkerhetsbekymringer som oppstår. 
						</p>
						<p className="text-black pb-8">
							Vi har etablert et samarbeid med politiet i Ringsaker for å sikre at samfunnsvernet ivaretas på en effektiv måte.
						</p>
						<Link href="https://www.politiet.no/om-politiet/organisasjonen/politidistrikter/innlandet/brumunddal-politistasjon/">
							<Image
								src={Politiet.src}
								alt={"politiet.no"}
								className="rounded fill-white select-none max-w-60 mx-auto pt-4"
								height={Politiet.height}
								width={Politiet.width}
							/>
						</Link>
					</div>
					<div className="md:col-span-2 flex flex-col justify-center relative">
						<Image
							src={Hands.src}
							alt={""}
							className="rounded fill-white select-none max-sm:pt-20 px-8"
							height={Hands.height}
							width={Hands.width}
							sizes="50vw"
						/>
					</div>
				</div>
			</section> */}
			<section id="kontakt" className="px-4 bg-card m-1">
				<div className="max-w-screen-xl m-auto col-span-2 flex flex-col justify-center text-center py-14">
					<h2 className="text-2xl font-bold pb-6">Spørsmål? Ta kontakt!</h2>
					<div className="pb-6">
						<p className="pb-3">
							Ta kontakt med daglig leder, Lasse Toroczkay, på telefon:
						</p>
						<a className="mx-auto text-lg font-semibold" href="tel:+4798082082">98 082 082</a>
					</div>
					<div className="pb-6">
						<p className="pb-3">
							For akutt bistand er vakttelefonen døgnåpen:
						</p>
						<a className="mx-auto text-lg font-semibold" href="tel:+4722330100">223 30 100</a>
					</div>
					<div className="pb-2">
						<p>
							For skriftlig henvendelser send en e-post til <a className="font-medium" href="mailto:sjefen@ascendi.no">sjefen@ascendi.no</a>. 
						</p>
					</div>
					<div className="pb-2">
						<p>
							For generelle henvendelser send en e-post til <a className="font-medium" href="mailto:hei@ascendi.no">hei@ascendi.no</a>.
						</p>
					</div>
				</div>
			</section>
		</>
	);
}
