"use client";

import React from "react";
import Link from "next/link";
// import staticImageLoader from "~/loaders/static-image-loader";

// // import dynamic from "next/dynamic";
// // const Button = dynamic(() => import('@nextui-org/button').then(m => m.Button), { loading: () => <button>Ta Kontakt!</button> })
// // import { Button } from "@nextui-org/button";

// // import HeroImage from "./girl.png";
// import HeroImage from "./hero.jpg";
// // import HeroImage from "./blue_look_right.jpeg";
// import Image from "next/image";

import style from "./header.module.css";
import { Button } from "@heroui/button";
import Arrow from "./right.svg";

export default function Header() {
    return (
        <header className={style.header} style={{ height:'40rem', backgroundColor:'#d10cb7' }}>
            <div className={style.content}>
                <div className={style.text}>
                    <div className={style.textbox} style={{ fontDisplay: 'swap', marginTop:'1.5rem', textAlign:'center' }}>
                        <h1 className="font-light">Velkommen til <span className="font-medium md:font-light">Ascendi</span></h1>
                        <p>
                            Vi tilbyr skreddersydde heldøgns bo- og omsorgstjenester i en støttende og trygg atmosfære. 
                        </p>
                        <div className={style.btn} style={{ margin:'3rem auto', alignContent:'center', width:'100%', padding:'0 3rem' }}>
                            <Button 
                                className="font-bold text-lg bg-[#2540e5] font-normal"
                                style={{ width:"100%", color:"#fff" }}
                                radius="md"
                                size="lg"
                                as={Link}
								href="/kontakt"
                            >
                                Ta kontakt
                                <Arrow/>
                            </Button>
                        </div>
                    </div>
                </div>
                {/* 35 rem */}
                {/* <div className={style.img + " select-none"}>
                    <div 
                        style={{ position:'relative', width:'85%', margin:'0 auto', paddingRight:"3.5rem", paddingTop:"2rem" }} 
                        className="flex flex-column justify-center" 
                        media="screen and (min-width: 900px)"
                    >
                        <Image
                            style={{ objectFit: "contain", userSelect:"none" }}
                            src={HeroImage}
                            width={HeroImage.width}
                            height={HeroImage.height}
                            loader={staticImageLoader}
                            sizes="33vw"
                            alt=""
                            // priority
                        />
                    </div>
                </div> */}
                {/* <div className={style.img}>
                    <div 
                        style={{ position:'relative', width:'100%', margin:'0 auto' }} 
                        className="flex flex-column justify-center" 
                        // media="screen and (max-width: 764px)"
                    >
                        <Image
                            style={{ objectFit: "contain", userSelect:"none" }}
                            src={HeroImage}
                            width={HeroImage.width}
                            height={HeroImage.height}
                            loader={staticImageLoader}
                            sizes="33vw"
                            alt=""
                            // priority
                        />
                    </div>
                </div> */}
            </div>
        </header>
    )
}