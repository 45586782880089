// TODO: Handle null image?
export default function SocialShare({ 
    title = "Ascendi | Heldøgn bo- og omsorgstjenester",
    description = "",
    image = "https://www.ascendi.no/preview.jpg",
    alt = ""
}) {
    return (
        <>
            {/* Open Graph (Facebook) */}
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:image" content={image}/>
            <meta property="og:image:alt" content={alt}/>
            <meta property="og:image:width" content="1200"/>
            <meta property="og:image:height" content="627"/>
            {/* Twitter (X) */}
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:url" content="https://www.ascendi.no/?src=twitter" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:image:src" content={image} />
            <meta name="twitter:image:alt" content={alt} />
        </>
    )
}
